import React from "react"
import { FaPhone } from "react-icons/fa"
export default [
  {
    id: 1,
    icon: <FaPhone />,
    company: "Asesoría laboral empresas",
    phone: "666583971",
    mail: "laboral@gu-asesoria.es ",
    desc: [
      {
        id: 1,
        name: "Contratos de trabajo",
      },
      {
        id: 2,
        name: "Recibos de salarios ",
      },
      {
        id: 3,
        name: "Liquidaciones seguridad social",
      },
      {
        id: 4,
        name: "Informes estadísticos y de costes",
      },
      {
        id: 5,
        name: "Encuestas",
      },
      {
        id: 6,
        name: "Estudios de personal y de recursos humanos",
      },
    ],
  },
  {
    id: 2,
    icon: <FaPhone />,
    company: "asesoría laboral particulares",
    phone: "666583971",
    mail: "laboral@gu-asesoria.es ",
    desc: [
      {
        id: 1,
        name: "Empleadas de Hogar",
      },
      {
        id: 2,
        name: "Altas contratos",
      },
      {
        id: 3,
        name: "Nóminas",
      },
      {
        id: 4,
        name: "Desistimientos",
      },
      {
        id: 5,
        name: "Finiquitos",
      },
      {
        id: 6,
        name: "Jubilaciones",
      },
      {
        id: 7,
        name: "Incapacidades",
      },
      {
        id: 8,
        name: "Estudios individualizados de situación laboral",
      },
      {
        id: 9,
        name: "Despidos",
      },
    ],
  },
  {
    id: 3,
    icon: <FaPhone />,
    company: "asesoría fiscal y contable",
    phone: "607906534",
    mail: "conta@gu-asesoria.es",
    desc: [
      {
        id: 1,
        name: "Representación ante Hacienda en la CAV y en todo el Estado",
      },
      {
        id: 2,
        name:
          "Gestión de Sociedades Mercantiles: Constitución y cambios societarios",
      },
      {
        id: 3,
        name: "Presentación de Declaraciones fiscales - Sociedades",
      },
      {
        id: 4,
        name: "Elaboración y revisión de contabilidades",
      },
      {
        id: 5,
        name: "Autónomos: asesoramiento contable y tributario completo",
      },
      {
        id: 6,
        name:
          "Asesoramiento de  Impuestos individuales : Renta- Sucesiones-Transmisiones patrimoniales",
      },
    ],
  },
  {
    id: 4,
    icon: <FaPhone />,
    company: "Asesoría jurídica",
    phone: "647584342",
    mail: "legal@gu-asesoria.es",
    desc: [
      {
        id: 1,
        name: "Asistencia en todo tipo de procedimientos judiciales en:",
      },
      {
        id: 2,
        name: "Asesoría laboral",
      },
      {
        id: 3,
        name: "Asesoría civil",
      },
      {
        id: 4,
        name: "Asesoría mercantil",
      },
    ],
  },
]
