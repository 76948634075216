import React from "react"
import Title from "./Title"
import { FaAngleDoubleRight } from "react-icons/fa"
import { Link } from "gatsby"
import info from "../constants/info"

const Jobs = () => {

  console.log('data', info)
  const [value, setValue] = React.useState(0)
  const { company, phone, mail, desc, icon, iconMail } = info[value];
  return <section className="section jobs">
    <Title title="¿Qué ofrecemos?"></Title>

    <div className="jobs-center">
      {/*button container*/}
      <div className="btn-container">
        {info.map((item, index) => {
          return <button
            key={item.id}
            onClick={() => setValue(index)}
            className={`job-btn ${index === value && 'active-btn'}`}>
            {item.company}
          </button>
        })}
      </div>

      {/*job info*/}
      <article className="job-info">
        <h3>{company}</h3>
        <h4>{icon} {phone}</h4>
        <p className="job-date">{iconMail} {mail}</p>
        {desc.map((item) => {
          return <div key={item.id} className="job-desc">
            <FaAngleDoubleRight className="job-icon"></FaAngleDoubleRight>
            <p>{item.name}</p>
          </div>
        })}
      </article>
    </div>
    <Link to="/nosotros" className="btn center-btn">más info</Link>
  </section>

}

export default Jobs
