import React from "react"
import Image from "gatsby-image"
import { graphql, useStaticQuery } from "gatsby"

const query = graphql`
  {
    file(relativePath: { eq: "project-2.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
const ProjectTwo = () => {
  const {
    file: {
      childImageSharp: { fluid },
    },
  } = useStaticQuery(query)
  const description =
    "Le ayudamos con una planificación fiscal personalizada, ajustada a las necesidades de su patrimonio o sociedad. IRPF, Sociedades, IVA, Sucesiones, Patrimonio, Transmisiones Patrimoniales, Impuestos locales… es necesario tener una visión global de toda la fiscalidad para asegurar sus inversiones."
  const stack = [
    { title: "Impuesto Renta y Patrimonio" },
    { title: "Inspecciones fiscales" },
    { title: "Requerimientos y Aplazamientos" },
    { title: "..." },
  ]
  const title = "Asesoramiento Fiscal "
  return (
    <article className="project">
      <Image fluid={fluid} className="project-img"></Image>
      <div className="project-info">
        <h3>{title}</h3>
        <p className="project-desc">{description}</p>
        <div className="project-stack">
          {stack.map((item, index) => {
            return <span key={index}> {item.title}</span>
          })}
        </div>
      </div>
    </article>
  )
}

export default ProjectTwo
