import React from "react"
//import { graphql } from "gatsby"
import Layout from "../components/Layout"
import Hero from "../components/Hero"
import Services from "../components/Services"
import Jobs from "../components/Jobs"
import Projects from "../components/Projects"
import SEO from "../components/SEO"

export default () => {
  return (
    <Layout>
      <SEO
        title="Inicio"
        description="Asesoría laboral en San Sebastian. Está formada por un equipo de abodagos expertos en todas las áreas más relevantes para la gestión de una empresa, como son el área mercantil, fiscal y tributaria, contable, laboral, declaracion de la renta, altas y bajas en la secguridad social, etc.."
      />
      <Hero />
      <Services />
      <Jobs />
      <Projects />
    </Layout>
  )
}
// ...GatsbyImageSharpFluid
