import React from "react"
import Image from "gatsby-image"
import { graphql, useStaticQuery } from "gatsby"

const query = graphql`
{
  file(relativePath: {eq: "project-1.jpg"}) {
    childImageSharp {
      fluid {
        ...GatsbyImageSharpFluid
      }
    }
  }
}
`
const ProjectOne = () => {
  const {
    file: {
      childImageSharp: { fluid }
    }
  } = useStaticQuery(query);
  const description = "Nuestros servicios están enfocados a la gestión íntegra de su personal: contratación laboral, nóminas mensuales, asesoramiento jurídico y todas las gestiones necesarias a lo largo de las diferentes etapas de la vida laboral de cada uno de sus empleados."
  const stack = [{ title: "gestión y envío de Salarios y Nominas" }, { title: "Altas, bajas Seguridad Social" }, { title: "Inspecciones de trabajo" }, { title: "..." }]
  const title = "Asesoramiento Laboral "
  return <article className="project">
    <Image fluid={fluid} className="project-img"></Image>
    <div className="project-info">
      <h3>{title}</h3>
      <p className="project-desc">{description}</p>
      <div className="project-stack">
        {stack.map((item, index) => {
          return <span key={index}> {item.title}</span>
        })}
      </div>
    </div>
  </article>
}

export default ProjectOne
