import React from "react"
import { FaPenNib, FaLandmark, FaPaperPlane } from "react-icons/fa"
export default [
  {
    id: 1,
    icon: <FaPenNib className="service-icon" />,
    title: "Asesoría integral",
    text: `Nuestros servicios engloban todo lo necesario para gestionar una empresa, desde información previa, hasta trámites, formación y realización de todo tipo de gestiones.`,
  },
  {
    id: 2,
    icon: <FaLandmark className="service-icon" />,
    title: "Laboral, fiscal y contable",
    text: `Nuestro equipo de Asesoría Guelbenzu Uralde incluye expertos de las áreas de laboral, fiscal y contable, los cuales le harán más fácil llevar su empresa en el día a día. Usted no tendrá que preocuparse por nada.`,
  },
  {
    id: 3,
    icon: <FaPaperPlane className="service-icon" />,
    title: "contacte con nosotros",
    text: `Llame ya a nuestro número de teléfono o deje sus datos a través de esta web. Contacte a través de nuestro formulario de contacto o llámenos al: 943 273 058`,
  },
]
