import React from "react"
import Image from "gatsby-image"
import { Link } from "gatsby"
import { FaPhone, FaEnvelope } from "react-icons/fa"
import { graphql, useStaticQuery } from "gatsby"

const query = graphql`
  {
    file(relativePath: { eq: "escaparate_03-D.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
const Hero = () => {
  const {
    file: {
      childImageSharp: { fluid },
    },
  } = useStaticQuery(query)
  return (
    <header className="hero">
      <div className="section-center hero-center">
        <article className="hero-info">
          <div>
            <div className="underline"></div>
            <h1>Asesoria laboral contable y fiscal</h1>
            <h4 class="normal">
              Sesenta años de experiencia acreditada nos avalan.
            </h4>
            <h4>
              <FaPhone></FaPhone> 943 273 058{" "}
            </h4>
            <h4>
              <FaEnvelope></FaEnvelope>
              <a href="mailto:laboral@gu-asesoria.es">laboral@gu-asesoria.es</a>
            </h4>
            <Link to="/contacto" className="btn">
              Contáctanos
            </Link>
          </div>
        </article>
        <Image fluid={fluid} className="asesoria-hero-img"></Image>
      </div>
    </header>
  )
}

export default Hero
