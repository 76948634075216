import React from "react"
import Title from "./Title"
import ProjectOne from "./ProjectOne"
import ProjectTwo from "./ProjectTwo"
import ProjectThree from "./ProjectThree"

const Projects = () => {
  return (
    <section className="section projects">
      <Title title="¿Por qué nosotros?"></Title>
      <div className="section-center projects-center">
        <ProjectOne></ProjectOne>
        <ProjectTwo></ProjectTwo>
        <ProjectThree></ProjectThree>
      </div>
    </section>
  )
}

export default Projects
