import React from "react"
import Image from "gatsby-image"
import { graphql, useStaticQuery } from "gatsby"

const query = graphql`
  {
    file(relativePath: { eq: "conta.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
const ProjectThree = () => {
  const {
    file: {
      childImageSharp: { fluid },
    },
  } = useStaticQuery(query)
  const description =
    "En Asesoría Guelbenzu Uralde ponemos a tu disposición un equipo de economistas de dilatada trayectoria, quienes cuentan con los conocimientos suficientes para anteponerse a cualquier situación adversa en tu gestión. Toda la tranquilidad que necesitas a la hora de tomar decisiones podrás encontrarla bajo el resguardo que te ofrece nuestra gestión contable."
  const stack = [
    { title: "Análisis financiero" },
    { title: "Reglamento de facturación" },
    { title: "Planificación" },
    { title: "..." },
  ]
  const title = "Asesoramiento integral en materia contable"
  return (
    <article className="project">
      <Image fluid={fluid} className="project-img"></Image>
      <div className="project-info">
        <h3>{title}</h3>
        <p className="project-desc">{description}</p>
        <div className="project-stack">
          {stack.map((item, index) => {
            return <span key={index}> {item.title}</span>
          })}
        </div>
      </div>
    </article>
  )
}

export default ProjectThree
